import 'bootstrap/dist/css/bootstrap.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import "./assets/scss/main.scss"
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


import router from './router'
const app = createApp(App);
app.use(router);
app.mount('#app');
