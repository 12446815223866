<template>
    <header>
      <div class="header-content">
        <a href="/">
          <img class="header-content__img" src="../assets/images/logo-play212.png" alt="logo play 212" />
        </a>
      </div>
    </header>
</template>

<script>
  export default {
    name: "HeaderComponent"
  };
</script>
