<template>
  <div class="content-agent">
    <a class="" :href="`https://agents.${BackEndUrl}`" target="_blank">REPORTS</a>
  </div>
</template>

<script>
  export default {
    name: "AgentsButtons",
    setup() {
      const BackEndUrl = "play212.com"

      return {
        BackEndUrl
      }
    },
  };
</script>
