<template>
    <main>
      <div class="content-form">
        <form name="LoginForm" action="javascript:void(0)" onsubmit="BackEndLogin(this); return false">

            <div class="form-group">
                <label class="sr-only" for="username">Username</label>
                <input type="text" class="form-control" name="username" placeholder="Username" required />
            </div>

            <div class="form-group">
                <label class="sr-only" for="password">Password</label>
                <input type="password" class="form-control" name="password" placeholder="Password" required />
            </div>

            <!-- detected error -->
            <div class="text-center mt-2">
                <small name="msj_error_lg" class="text-danger"></small>
            </div>

            <div class="form-group">
                <div class="controls">
                    <button type="submit" name="btn-login" class="btn btn-success btn-block idt">
                        <span name="msj_loading" id="id-login-loading"></span>
                        <span name="msj_login">Login</span>
                    </button>
                </div>
            </div>

            <input type="hidden" name="BackEndUrl" :value="`https://${backendUrl}`">
        </form>

          <AgentsButtons/>
      </div>
      <div class="container-link-classic">
        <p>Go to <a class="link-classic" :href="`https://${classicUrl}`"> 33blue.ag </a> if you wish to use our old theme</p>
      </div>
    </main>
</template>

<script>

  import AgentsButtons from './agent-button.vue';

  export default {
    name: "FormComponent",

    components: {
      AgentsButtons
    },

    setup() {
      const backendUrl = "betslip.play212.com";
      const classicUrl = "33blue.ag";

      return {
        backendUrl,
        classicUrl
      }
    },
  };
</script>