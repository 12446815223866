<template>
  <Fragment>
    <HeaderComponent/>
    <FormComponent/>
  </Fragment>
</template>

<script>
  import HeaderComponent from "@/components/header.vue"
  import FormComponent from "@/components/form.vue"

  export default {
    name: "PageHome",
    components: {
      HeaderComponent,
      FormComponent
    }
  }
</script>